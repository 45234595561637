<template>
  <div class="ad-manage">
    <a-form
      ref="formRef1"
      :model="formModal1.data"
      :rules="formModal1.rules"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 14 }"
    >
      <a-form-item label="是否显示" name="available">
        <a-select v-model:value="formModal1.data.available">
          <!-- <a-select-option :value="1">显示</a-select-option> -->
          <!-- <a-select-option :value="0">隐藏</a-select-option> -->
          <a-select-option :value="1">一个</a-select-option>
          <a-select-option :value="2">两个</a-select-option>
          <a-select-option :value="0">无</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="广告1类型" name="adType">
        <a-select v-model:value="formModal1.data.adType">
          <a-select-option :value="1">淘宝地址</a-select-option>
          <a-select-option :value="2">京东地址</a-select-option>
          <a-select-option :value="3">内部地址</a-select-option>
          <a-select-option :value="4">外部地址</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="跳转地址" name="targetUrl">
        <a-input v-model:value="formModal1.data.targetUrl" />
      </a-form-item>
      <a-form-item label="图片" name="pictUrl">
        <a-upload
          v-model:fileList="fileList1"
          name="file"
          accept="image/*"
          list-type="picture"
          :action="qiniuUrl"
          :data="fileData1"
          :beforeUpload="handlerBeforeUpload1"
          @change="handlerFileChange1"
        >
          <a-button>上传</a-button>
        </a-upload>
      </a-form-item>
    </a-form>
    <a-form
      ref="formRef2"
      :model="formModal2.data"
      :rules="formModal2.rules"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 14 }"
    >
      <a-form-item label="广告2类型" name="adType">
        <a-select v-model:value="formModal2.data.adType">
          <a-select-option :value="1">淘宝地址</a-select-option>
          <a-select-option :value="2">京东地址</a-select-option>
          <a-select-option :value="3">内部地址</a-select-option>
          <a-select-option :value="4">外部地址</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="跳转地址" name="targetUrl">
        <a-input v-model:value="formModal2.data.targetUrl" />
      </a-form-item>
      <a-form-item label="图片" name="pictUrl">
        <a-upload
          v-model:fileList="fileList2"
          name="file"
          accept="image/*"
          list-type="picture"
          :action="qiniuUrl"
          :data="fileData2"
          :beforeUpload="handlerBeforeUpload2"
          @change="handlerFileChange2"
        >
          <a-button>上传</a-button>
        </a-upload>
      </a-form-item>
    </a-form>
    <div class="ad-manage-btn">
      <a-button type="primary" @click="confirm">保存</a-button>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import urls from "@/common/urls.js";
import { uploadFile } from "@/api/planet.js";
import { updateAd, getAllAds } from "@/api/active.js";
import { message } from "ant-design-vue";

export default {
  setup() {
    const qiniuUrl = ref(urls.qiniuBase);

    const formRef1 = ref();
    const formModal1 = ref({
      data: {
        available: 1,
        adType: null,
        pictUrl: "",
        targetUrl: ""
      },
      rules: {
        pictUrl: [{ required: true, message: "必填项", trigger: "blur" }]
      }
    });
    const fileList1 = ref([]);
    const fileData1 = ref({});
    const handlerBeforeUpload1 = async file => {
      const { data } = await uploadFile(file.name);
      fileData1.value = data;
    };
    const handlerFileChange1 = ({ file }) => {
      const { status, response } = file;
      if (status === "done") {
        formModal1.value.data.pictUrl = `${urls.qiniu}${response.key}`;
        if (response.key) {
          fileList1.value = [
            {
              name: response.key,
              response: { key: response.key },
              status: "done",
              url: `${urls.qiniu}${response.key}`,
              uid: "-1"
            }
          ];
        }
      }
    };

    const formRef2 = ref();
    const formModal2 = ref({
      data: {
        available: 1,
        adType: null,
        pictUrl: "",
        targetUrl: ""
      },
      rules: {
        pictUrl: [{ required: true, message: "必填项", trigger: "blur" }]
      }
    });
    const fileList2 = ref([]);
    const fileData2 = ref({});
    const handlerBeforeUpload2 = async file => {
      const { data } = await uploadFile(file.name);
      fileData2.value = data;
    };
    const handlerFileChange2 = ({ file }) => {
      const { status, response } = file;
      if (status === "done") {
        formModal2.value.data.pictUrl = `${urls.qiniu}${response.key}`;
        if (response.key) {
          fileList2.value = [
            {
              name: response.key,
              response: { key: response.key },
              status: "done",
              url: `${urls.qiniu}${response.key}`,
              uid: "-1"
            }
          ];
        }
      }
    };

    const confirm = async () => {
      try {
        await formRef1.value.validate();
        await formRef2.value.validate();
        const params = [
          {
            ...formModal1.value.data,
            available: formModal1.value.data.available
          },
          {
            ...formModal2.value.data,
            available: formModal1.value.data.available
          }
        ];
        const { status } = await updateAd(params);
        if (status === "200") {
          message.success("更新成功！");
        }
      } catch (error) {
        return;
      }
    };
    const getAds = async () => {
      const { data } = await getAllAds();
      if (data && data.length > 0) {
        formModal1.value.data = {
          id: data[0].id,
          logicId: data[0].logicId,
          adType: data[0].adType,
          pictUrl: data[0].pictUrl,
          targetUrl: data[0].targetUrl,
          available: data[0].available
        };
        formModal2.value.data = {
          id: data[1].id,
          logicId: data[1].logicId,
          adType: data[1].adType,
          pictUrl: data[1].pictUrl,
          targetUrl: data[1].targetUrl,
          available: data[1].available
        };
        if (data[0].pictUrl) {
          const key = data[0].pictUrl.split("/").pop();
          fileList1.value = [
            {
              name: key || "",
              response: { key: key || "" },
              status: "done",
              url: data[0].pictUrl,
              uid: "-1"
            }
          ];
        }
        if (data[1].pictUrl) {
          const key = data[1].pictUrl.split("/").pop();
          fileList2.value = [
            {
              name: key || "",
              response: { key: key || "" },
              status: "done",
              url: data[1].pictUrl,
              uid: "-1"
            }
          ];
        }
      }
    };

    onMounted(getAds);

    return {
      qiniuUrl,

      formRef1,
      formModal1,
      fileList1,
      fileData1,
      handlerBeforeUpload1,
      handlerFileChange1,
      formRef2,
      formModal2,
      fileList2,
      fileData2,
      handlerBeforeUpload2,
      handlerFileChange2,

      confirm
    };
  }
};
</script>
<style scoped>
.ad-manage {
  width: 600px;
  margin: 20px auto 0;
}
.ad-manage-btn {
  text-align: center;
}
</style>